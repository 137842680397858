<template>
  <div class="copyright">
    <div class="container">
      <div class="row justify-content-center my-5 fs-5">
        <div class="col-md-10">
          <h4 class="pt-lg-3 pb-3 pb-lg-4 fw-bold">著作權聲明</h4>
          <div class="d-flex justify-content-center mt-3">
            <i class="bi bi-diamond-fill me-1 mt-1 fs-9"></i>
            <div class="fs-6 text-break word">
                Papawash 上刊載的所有內容，包括但不限於文字報導、照片、影像、插圖、錄音片、影音片等素材，均受到中華民國著作權法及國際著作權法律的保障。網站內容的著作權為 Papawash ，或原授權 Papawash 使用之合作夥伴所有。
            </div>
          </div>
          <div class="d-flex justify-content-start mt-2">
            <i class="bi bi-diamond-fill me-1 mt-1 fs-9"></i>
            <div class="fs-6 text-break word">
                Papawash 提供的內容與服務，是為了使用者個人、非商業用途的使用，使用人利用時必須遵守著作權法的所有相關規定。
            </div>
          </div>
          <div class="d-flex justify-content-start mt-2">
            <i class="bi bi-diamond-fill me-1 mt-1 fs-9"></i>
            <div class="fs-6 text-break word">
                使用者不可變更、發行、播送、轉賣、重製、改作、散布、表演、展示或利用 Papawash 的局部或全部的內容或服務，以賺取利益。
            </div>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <i class="bi bi-diamond-fill me-1 mt-1 fs-9"></i>
            <div class="fs-6 text-break word">
                使用者下載或拷貝網站的內容或服務僅可供個人、非商業用途之使用。個人使用以外之其他用途，在未取得 Papawash、或其他內容提供者著作人書面許可之前，完全禁止。
            </div>
          </div>
          <div class="d-flex justify-content-start mt-2">
            <i class="bi bi-diamond-fill me-1 mt-1 fs-9"></i>
            <div class="fs-6 text-break word">
                請求同意連結到 Papawash，要求轉載 Papawash內容、服務或請求其他利用，請洽 Papawash 客服信箱。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.container {
  min-height: calc(100vh - 500px);
  line-height: 2;
}
</style>
